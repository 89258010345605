import React from "react";

export function NavBack(props){

    const handleClickGo = (event) => {
        window.history.back(); 
    };
    return(
        <div className="nav-db">
            <div onClick={(e) => handleClickGo(e)}>Ir atrás</div>
        </div>
    )  
}