import React from 'react'
import {url, endpointDownloadBrokenLinks} from '../services/Endpoints'

function DownloadCsvBrokenLinks(props) {


    const handleClickDownload = (e) => {
        e.preventDefault();
        fetch(`${url}${endpointDownloadBrokenLinks}?id=${props.onixId}`)
        .then(response => response.blob())
        .then(blob => {
            // Crea un objeto URL para el archivo CSV y crea un enlace de descarga
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'broken links date '+props.onixId+'.csv');
            document.body.appendChild(link);
            link.click();
        })
        .catch(error => console.error(error));
    }
    return (
        <div className='download-csv'><button onClick={(e) => handleClickDownload(e)}>Links rotos</button></div>
    )
}

export default DownloadCsvBrokenLinks