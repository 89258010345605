import React from "react";
import { useParams } from "react-router-dom";
import FileUploader from "./FileUploader";
import { Heading } from "./Heading";
import { NavBack } from "./NavBack";

export function FormConversion(props){
    const { account, id } = useParams();
    const title = 'Subir conversión';

    return (
        <>
        <Heading title={title}/>
        <NavBack/>
        <div className="d-flex justify-content-center">
            <h4>Proyecto {account}-C-{id}</h4>
        </div>
        <FileUploader account={account} ink_id={id}/>
        </>
    )

}

export default FormConversion;