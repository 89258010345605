import React from 'react'
import { useParams } from 'react-router-dom'
import { Heading } from '../components/Heading';
import { ProductsList } from '../components/ProductsList'
import DownloadCsv from '../components/DownloadCsv';
import DownloadCsvBrokenLink from '../components/DownloadCsvBrokenLinks';
import { NavBack } from '../components/NavBack';

export function Products() {
    const { onixId } = useParams();
    const title = 'Productos';
    return (
      <>
        <Heading title={title}/>
        <NavBack/>
        <DownloadCsv onixId={onixId} />
        <DownloadCsvBrokenLink onixId={onixId} />
        <ProductsList onixId={onixId}/>
      </>
      
    )
}