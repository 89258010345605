import React, { useEffect, useState } from "react"
import { endpointDownloadCSVStock, endpointDownloadXlsxStock, endpointGetAccounts, endpointGetStock, urlDistribution, urlInk } from "../services/Endpoints";
import { Loading } from "./Loading";
import Paginator from "./Paginator";
import Autocomplete from "./Autocomplete";
import { faClose, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import UploadStock from "./UploadStock";

export function StockList(){

    const [loading, setLoading] = useState(false);
    const [projects, setProjects] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalProjects, setTotalPorjects] = useState(1);

    const [accounts, setAccounts] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState('');
    const [search, setSearch] = useState('');
    const [labelDownload, setLabelDownload] = useState('Descargar CSV');
    const [labelDownloadXlsx, setLabelDownloadXlsx] = useState('Descargar Xlsx');

    const [showUpload, setShowUpload] = useState(false);


    useEffect(()=>{
        getStock();
        getAccounts();
        return() => {
            setProjects(() => []);
            setAccounts(() => []);
          }
    },[currentPage, selectedAccount]);

    const getStock = async () => {
        try {
            setLoading(true);
            const response = await fetch(`${urlDistribution}${endpointGetStock}?page=${currentPage}&account=${selectedAccount}&filter=${search}`, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json"
                }
              });
            const res = await response.json();
            if(res && res.status === 'success'){
                setTotalPorjects(res.data.total_count)
                setProjects(res.data.projects);
    
            }else{
                
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    const getAccounts = async () => {
        try {
            const response = await fetch(`${urlInk}${endpointGetAccounts}`, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json"
                }
              });
            const res = await response.json();
            if(res && res.status === 'success'){
                setAccounts(res.data);
    
            }else{
                
            }
        } catch (error) {
        }
    }

    const handlePageChange = (page) => {
        setCurrentPage(page)
    };

    const handleShowMissingData = (e, project) => {
        e.preventDefault();
        const updated = projects.map((element) => {
            if (project.account === element.account && project.ink_id === element.ink_id && element.required.valid === false && element.show === false) {
                return { ...element, show: true };
            } else {
                return { ...element, show: false };
            }
        });
  
        setProjects(updated);
    };

    const handleChange = (option) => {
        console.log(option)
        setSelectedAccount(option.value);
      };

    const handleSearchInputChange = (e) => {
        setSearch(e.target.value);
        if(search === ''){
            getStock();
        }
    };
    
    const handleClearSearch = () => {
        setSearch('');
    };

    const handleClickSearch = () => {
        getStock();
    }

    const handleKeyPressSearch = (e) => {
        if(e.key === 'Enter' && search !== ''){
            getStock();
        }

        if(e.key === 'Backspace' && search === ''){
            getStock();
        }
        
    }

    const handleClickDownloadCSV = (e) => {
        e.preventDefault();
        setLoading(true);
        setLabelDownload('Descargando...')
        fetch(`${urlDistribution}${endpointDownloadCSVStock}?account=${selectedAccount}&filter=${search}`)
        .then(response => response.blob())
        .then(blob => {
            // Crea un objeto URL para el archivo CSV y crea un enlace de descarga
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'catalogo.csv');
            document.body.appendChild(link);
            link.click();
            setLoading(false);
            setLabelDownload('Descargar CSV');
        })
        .catch(error => console.error(error));
    }

    const handleClickDownloadXlsx = (e) => {
        e.preventDefault();
        setLoading(true);
        setLabelDownloadXlsx('Descargando...')
        fetch(`${urlDistribution}${endpointDownloadXlsxStock}?account=${selectedAccount}&filter=${search}`)
        .then(response => response.blob())
        .then(blob => {
            // Crea un objeto URL para el archivo CSV y crea un enlace de descarga
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'catalogo.xlsx');
            document.body.appendChild(link);
            link.click();
            setLoading(false);
            setLabelDownloadXlsx('Descargar Xlsx');
        })
        .catch(error => console.error(error));
    }

    const handleClickUpload = () => {
        setShowUpload(true)
    }

    

    return(
        <>
        <div className="d-flex align-items-end">
            <div style={{width: 350, marginRight: 8}}>
                <label>Cuentas</label>
                <Autocomplete options={accounts} onChange={handleChange} />
            </div>
            <div className="filters">
                {selectedAccount !== '' && 
                    <div className="d-flex tag">
                        <div>
                            {selectedAccount}
                        </div>
                        <div className="close action" onClick={()=>setSelectedAccount('')}>
                            <FontAwesomeIcon icon={faClose} />
                        </div>
                    </div>
                }
            </div>
        </div>
        
        <div style={{width: 500}}>
            <label>Buscar</label>
            <div className="d-flex">
                <div className="d-flex search" style={{marginRight: 8}}>
                    <input
                        type="text"
                        placeholder="Título, ISBN, INK ID, 656, 409"
                        value={search}
                        onKeyUp={handleKeyPressSearch}
                        onChange={handleSearchInputChange}
                    />
                    {search === '' ? (
                        <button>
                        <FontAwesomeIcon icon={faSearch} />
                        </button>
                    ) : (
                        <button onClick={handleClearSearch}>
                        <FontAwesomeIcon icon={faClose} />
                        </button>
                    )}
                </div>
                <Button variant="primary" onClick={handleClickSearch}>
                    Buscar
                </Button>
            </div>
            
            
        </div>
        <div className="row">
            <div className="d-flex justify-content-end">
                {/* <Button variant="primary"  onClick={handleClickDownloadCSV} disabled={loading === true}>{labelDownload}</Button>{' '}
                <Button variant="primary" onClick={handleClickDownloadXlsx} disabled={loading === true}>{labelDownloadXlsx}</Button>{' '} */}
                <Button variant="primary"  onClick={handleClickUpload}>Cargar catálogo</Button>
                <UploadStock showUpload={showUpload} setShowUpload={setShowUpload}/>
                <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        Descargar
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={handleClickDownloadCSV} disabled={loading === true} href="javascript:void()">{labelDownload}</Dropdown.Item>
                        <Dropdown.Item onClick={handleClickDownloadXlsx} disabled={loading === true} href="javascript:void()">{labelDownloadXlsx}</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                
            </div>
            
        </div>
        <Paginator totalItems={totalProjects} itemsPerPage={10}  onPageChange={handlePageChange} />
        
        
        {loading === true &&
            <Loading />
        }
        <table className="table-stock">
            <thead>
                <tr>
                <th>Info.</th><th>Cuenta</th><th>Ink id</th><th>Título</th><th>ISBN</th><th>Kobo id</th><th>ISBN alterno</th>
                </tr>
            </thead>
            <tbody>
                {
                    projects.map((project, index) => {
                        return  <>
                                <tr key={index} >
                                    <td className={project.required.valid === true ? 'short completed' : 'short missing'} onClick={(e)=>handleShowMissingData(e, project)}>{project.required.valid === true ? 'Completado' : 'Error'}
                                        {(project.required.valid === false && project.required.missing.length > 0 && project.show === true) &&
                                            <div className="tooltip">
                                                <div>Datos faltantes:</div>
                                                <ul>
                                                {project.required.missing.map((elem, pos) => {
                                                    return  <li>
                                                                {elem}
                                                            </li>
                                                })}
                                                </ul>
                                            </div>
                                        }
                                    </td>      
                                    <td className="short">{project.account}</td>
                                    <td className="short">{project.ink_id}</td>
                                    <td>{project.title}</td>
                                    <td>{project.isbn}</td>
                                    <td>{project['656_id'] === 0 ? '-' : project['656_id']}</td>
                                    <td>{project['409_id'] === 0 ? '-' : project['409_id']}</td>
                                </tr>
                                
                                </>
                    })
                }
            </tbody>
        </table>
        </>
    )

}

export default StockList