import React from 'react'
import {Router} from './Router'
import 'bootstrap/dist/css/bootstrap.min.css';

export function App(){

    return (
        <section className='content'>
            <Router />
            
        </section>
        
    )
}