import React, { useEffect, useState } from "react"
import { endpointGetConversions, urlConversion } from "../services/Endpoints";
import { Loading } from "./Loading";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";

export function ConversionList(){

    const [loading, setLoading] = useState(false);
    const [projects, setProjects] = useState([]);
    const navigate = useNavigate();

    useEffect(()=>{
        getConversions();
        return() => {
            setProjects(() => [])
          }
    },[]);

    const handleClickOpenConversion = (project) => {
        //e.preventDefault();
        navigate(`/conversions/${project.account}/${project.ink_id}`)
    }

    const getConversions = async () => {
        try {
            setLoading(true);
            const response = await fetch(`${urlConversion}${endpointGetConversions}`, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json"
                }
              });
            const res = await response.json();
            if(res && res.status == 'success'){
                setProjects(res.data.projects);
    
            }else{
                
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }
    

    return(
        <>
        {loading == true &&
            <Loading />
        }
        <table className="table-stock">
            <thead>
                <tr key={50000}>
                <th>Info.</th><th>Cuenta</th><th>Ink id</th><th>Título</th><th></th>
                </tr>
            </thead>
            <tbody>
                {
                    projects.map((project, index) => {
                        let status = 'Pendiente';
                        if(project.status == 1){
                            status = 'Aprobado y terminado';
                        }else if(project.status == 0){
                            status = 'Nuevo';
                        }else if(project.status == 2){
                            status = 'Pendiente de aprobación';
                        }
                        return  <>
                                <tr key={index} >
                                    <td className="short">{status}</td>      
                                    <td className="short">{project.account}</td>
                                    <td className="short">{project.ink_id}</td>
                                    <td>{project.title}</td>
                                    <td>
                                        {project.status != 1 && 
                                            <div onClick={(e) => handleClickOpenConversion(project)} className="d-flex action">
                                                <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                                                <div>Cargar conversión</div>
                                            </div>
                                        }
                                        
                                    </td>
                                </tr>
                                
                                </>
                    })
                }
            </tbody>
        </table>
        </>
    )

}

export default ConversionList