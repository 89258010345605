import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Index from "./pages/Index";
import { Login } from "./pages/Login";
import { Products } from "./pages/Products";
import { Dashboard } from "./pages/Dashboard";
import { FormNotifications } from "./components/FormNotifications";
import { Delivery } from "./pages/Delivery";
import Stock from "./pages/Stock";
import Conversion from "./pages/Conversion";
import FormConversion from "./components/FormConversion";
import WindowStatusProcess from "./components/WindowStatusProcess";

export const Router = () => {
    const [requestStatus, setRequestStatus] = useState('close');
    const [responseText, setResponseText] = useState([]);

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/login" element={<Login />} />
                <Route path="/onix" element={<Index />} />
                <Route path="/onix/:onixId" element={<Products />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/notifications" element={<FormNotifications requestStatus={requestStatus} responseText={responseText} setRequestStatus={setRequestStatus} setResponseText={setResponseText} />} />
                <Route path="/delivery" element={<Delivery />} />
                <Route path="/stock" element={<Stock />} />
                <Route path="/conversions" element={<Conversion />} />
                <Route path="/conversions/:account/:id" element={<FormConversion />} />
            </Routes>
            <WindowStatusProcess requestStatus={requestStatus} responseText={responseText} />
        </BrowserRouter>
    );
};
