import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {url, endpointLogin} from '../services/Endpoints'
import Button from 'react-bootstrap/Button';

export const FormLogin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`${url}${endpointLogin}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ username, password })
      });
      const data = await response.json();
      if(data && data.status == 'success'){
        navigate('/dashboard');
      }else{
        alert(data.msg);
      }
      
      //console.log("Login successful:", data);
    } catch (error) {
      console.error("Login failed:", error);
    }
  };
  

  return (
    <form className="form-login" onSubmit={handleSubmit}>
      <div>
        <label htmlFor="username">Username:</label>
        <input
          type="text"
          id="username"
          name="username"
          value={username}
          onChange={handleUsernameChange}
        />
      </div>
      <div>
        <label htmlFor="password">Password:</label>
        <input
          type="password"
          id="password"
          name="password"
          value={password}
          onChange={handlePasswordChange}
        />
      </div>
      <Button variant="primary" type="submit">Login</Button>
    </form>
  );
}
