import React from 'react'

import { Heading } from '../components/Heading';
import { NavDashboard } from '../components/NavDashboard';
import Channels from '../components/Channels';
import { useNavigate } from "react-router-dom";

export  function Delivery() {

  const navigate = useNavigate();

  const handleClickNotifications = (e) => {
      e.preventDefault();
      navigate('/notifications');
  }

  const title = 'Delivery';
  return (
        <>
          <Heading title={title}/>
          <NavDashboard/>
          <button onClick={(e) => handleClickNotifications(e)}>Agregar notificaciones</button>
          <Channels/>
        </>
        
      )
  }
  
  export default Delivery