import React from 'react'
import { Modules } from '../components/Modules'
import { Heading } from '../components/Heading';

export  function Dashboard() {

  const title = 'Dashboard';
  return (
      <>
        <Heading title={title}/>
        <Modules />
      </>
      
    )
}

export default Dashboard