import React, { Component } from 'react'
import { Loading } from './Loading';
import { OnixItem } from "./OnixItem";
import {url, endpointOnixList} from '../services/Endpoints'

export class OnixList extends Component{

    constructor(props){
        super(props);
        this.state = {
            onixList: [],
            isLoaded: false,
            error: null
        };
        this.url = `${url}${endpointOnixList}`;
    }

    componentDidMount(){
        this.getOnixList();
    }

    getOnixList(){
        fetch(this.url)
            .then(res => res.json())
            .then(
                (result) => {
                    //console.log(result.data);
                    this.setState({
                        onixList: result.data,
                        isLoaded: true
                        
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
            .catch(error => {
                console.error('Error al obtener datos:', error);
            });
    }

    render (){
        const { error, isLoaded, onixList } = this.state;
        
        if(error) {
            return <div>Error: {error.message}</div>;
        } else if(!isLoaded){
            return <Loading />
        }else{
            return (
                
                onixList.map((elem, index) => {
                    return <OnixItem key={index} onixId={elem}/>
                })
            );
        }
    }
}