import React, { useState } from "react";
import { url, endpointSetNotifications } from '../services/Endpoints';
import { Heading } from '../components/Heading';
import { NavBack } from "./NavBack";

export const FormNotifications = ({ requestStatus, responseText, setRequestStatus, setResponseText }) => {
  const [channels, setChannels] = useState("amazon,bookmate,storytel,ibooks,overdrive,tagus,bookbeat,kobo,playbooks,bn,scribd,bibliotheca,ebsco,odilo,findaway,unebook,perlego,huawei,stary");
  const [inkIds, setInkIds] = useState("");
  const [done, setDone] = useState(0);
  const [notificationType, setNotificationType] = useState("3");
  const [sending, setSending] = useState(false);
  const [labelButton, setLabelButton] = useState('Enviar');
  const [errors, setErrors] = useState([]);

  const title = 'Agrega notificaciones';

  const handleChannelsChange = (event) => {
    setChannels(event.target.value);
  };

  const handleInkIdsChange = (event) => {
    setInkIds(event.target.value);
  };

  const handleNotificationTypeChange = (event) => {
    setNotificationType(event.target.value);
  };

  const handleDoneChange = (event) => {
    if (event.target.checked) {
      setDone(1);
    } else {
      setDone(0);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      //setSending(true);
      //setLabelButton('Enviando...');
      setRequestStatus('pending'); // Actualiza el estado de la solicitud a 'pending' al enviar la solicitud
      const response = await fetch(`${url}${endpointSetNotifications}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ channels, inkIds, notificationType, done })
      });
      const data = await response.json();
      if (data && data.status === 'success') {
        setRequestStatus('completed'); // Actualiza el estado de la solicitud a 'completed' al recibir una respuesta exitosa
        setResponseText([data.msg]); // Actualiza el texto de la respuesta
      } else {
        if (data.details != undefined && data.details.length > 0) {
          setErrors(data.details);
          setResponseText(data.details);
        }
        setRequestStatus('completed'); // Actualiza el estado de la solicitud a 'completed' al recibir una respuesta con error
        setResponseText(data.details);
      }
      //setSending(false);
      //setLabelButton('Enviar');
    } catch (error) {
      setRequestStatus('completed'); // Actualiza el estado de la solicitud a 'completed' en caso de error
      setResponseText(['Error en el servidor']);
    }
  };

  return (
    <div>
      <Heading title={title}/>
      <NavBack/>
      {errors.length > 0 && (
        <ul className="errors-notifications">
          {errors.map((error, index) => (
            <li key={index}>{error}</li>
          ))}
        </ul>
      )}
      <form className="form-notif" onSubmit={handleSubmit}>
        <div>
          <label htmlFor="channels">Canales:</label>
          <textarea
            name="channels"
            rows="10"
            cols="100"
            id="channels"
            value={channels}
            onChange={handleChannelsChange}
          />
        </div>
        <div>
          <label htmlFor="inkIds">Ink ids:</label>
          <textarea
            name="inkIds"
            rows="10"
            cols="100"
            id="inkids"
            value={inkIds}
            onChange={handleInkIdsChange}
          />
        </div>
        <div>
          <label htmlFor="notificationType">Tipo de notificación</label>
          <select
            name="notificationType"
            id="notificationType"
            value={notificationType}
            onChange={handleNotificationTypeChange}
          >
            <option value="3">Alta</option>
            <option value="4">Actualización</option>
            <option value="5">Baja</option>
          </select>
        </div>
        <div className="d-flex align-items-center">
          <input
            type="checkbox"
            value={done}
            onChange={handleDoneChange}
          />
          <label>Terminado</label>
        </div>
        <button type="submit" disabled={sending || inkIds === '' || channels === ''}>{labelButton}</button>
      </form>
      {/* <WindowStatusProcess requestStatus={requestStatus} responseText={responseText} /> */}
    </div>
  );
};
