import React from 'react'

import { Heading } from '../components/Heading';
import { NavDashboard } from '../components/NavDashboard';
import ConversionList from '../components/ConversionList';

export  function Conversion() {

    const title = 'Conversiones';
    return (
        <>
          <Heading title={title}/>
          <NavDashboard/>
          <ConversionList/>
        </>
        
      )
  }
  
  export default Conversion