import React from 'react'
import { FormLogin } from "../components/FormLogin";
import { Heading } from '../components/Heading';

export function Login() {
  const title = 'Login';
  return (
      <>
        <Heading title={title}/>
        <FormLogin />
      </>
     
    )
}