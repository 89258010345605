import { faArrowLeft, faArrowRight, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";

function Paginator({ totalItems, itemsPerPage, onPageChange }) {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  useEffect(() => {
    onPageChange(currentPage);
    return() => {
        
      }
  }, [currentPage, onPageChange]);

  // Función para ir a la página anterior
  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Función para ir a la página siguiente
  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className="d-flex justify-content-end align-items-center paginator">
      <span>Página {currentPage} de {totalPages}</span>
      <button onClick={goToPreviousPage} disabled={currentPage === 1}><FontAwesomeIcon icon={faChevronLeft} /></button>
      <button onClick={goToNextPage} disabled={currentPage === totalPages}><FontAwesomeIcon icon={faChevronRight} /></button>
    </div>
  );
}

export default Paginator;
