import React, { useState, useEffect, useCallback } from 'react'
import { Loading } from './Loading';
import { NavProducts } from './NavProducts';
import { ProductItem } from './ProductItem';
import { MdArrowDropDown } from 'react-icons/md';

import {url, endpointDataByOnix, endpointValidateEpub, endpointSendInkit, endpointNotify, endpointUploadAssets} from '../services/Endpoints'

export function ProductsList(props){

    const [data, setData] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [tabSelected, setTabSelected] = useState('03');
    const [action, setAction] = useState('');


    const handleClickTab = (notificationType) => {
        setTabSelected(notificationType);
    }

    const handleClickValidateEpub = (e) => {
        e.preventDefault();
        setAction('validate_epub');
        validateEpub();
    }

    const handleClickSendInkit = (e) => {
        e.preventDefault();
        setAction('send_inkit');
        sendToInkit();
    }

    const handleClickUploadAssets = (e) => {
        e.preventDefault();
        uploadAssets();
    }

    const handleClickNotify = (e) => {
      e.preventDefault();
      notify();
    }

    const handleClickSortStatusEpub = (e) => {
        e.preventDefault();
        productsList('status_epub.valid')
    }

    useEffect(() => {
        productsList();
        return() => {
            setData(() => [])
        }
    }, [tabSelected]);

    const callSendToInkit = async (elem) => {
        const response = await fetch(`${url}${endpointSendInkit}?id=${props.onixId}&provider_id=${elem.provider_id}`);
        const data = await response.json();
        return data;
      }
      
    const sendToInkit = async () => {
        for (const elem of data) {
          if (((elem.ebook_type === 'epub' && elem?.status_epub?.valid === true && tabSelected === '03')
          || (elem.ebook_type === 'epub' && tabSelected === '04') || elem.ebook_type === 'pdf')) {
            setData(data.map(data => {
              if (data.provider_id === elem.provider_id) {
                data.loading = true;
              }
              return data;
            }));
      
            const result = await callSendToInkit(elem);
            if (result && result.status === 'success') {
              setData(data.map(data => {
                if (data.provider_id === elem.provider_id) {
                  data.ink_id = result.data;
                  data.loading = false;
                }
                return data;
              }));
            }
          }
        }
      };

    const callUploadAssets = async (elem) => {
        const response = await fetch(`${url}${endpointUploadAssets}?id=${props.onixId}&provider_id=${elem.provider_id}`);
        const data = await response.json();
        return data;
      }
      
    const uploadAssets = async () => {
      //let i = 0;
        for (const elem of data) {
          //if(i == 1) break;
          if (((elem.ebook_type === 'epub' && elem?.status_epub?.valid === true && elem.ink_id !== undefined) || (elem.ebook_type === 'pdf' && elem.ink_id !== undefined)) 
          && (elem.uploaded === undefined || elem.uploaded !== true)) {
            setData(data.map(data => {
              if (data.provider_id === elem.provider_id) {
                data.loading = true;
              }
              return data;
            }));
      
            const result = await callUploadAssets(elem);
            if (result && result.status === 'success') {
              setData(data.map(data => {
                if (data.provider_id === elem.provider_id) {
                  data.uploaded = result.data;
                  data.loading = false;
                }
                return data;
              }));
            }
          }
          //i++;
        }
      };


   
    

    const productsList = useCallback((sortBy = 'ebook_type') => {
        setIsLoaded(false);
        
        fetch(`${url}${endpointDataByOnix}?id=${props.onixId}&notification_type=${tabSelected}&sortedBy=${sortBy}`)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    //setData(result.data);
                    setData(result.data.map(data => {
                        data.loading = false;
                        return data;
                    }));
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
            .catch(error => {
                console.error('Error al obtener datos:', error);
            });
      }, [tabSelected, props.onixId]);


    const callValidateEpub = async (elem) => {
        const response = await fetch(`${url}${endpointValidateEpub}?onix_id=${props.onixId}&provider_id=${elem.provider_id}&path=${elem.ebook}`);
        const data = await response.json();
        return data;
      }
      
    const validateEpub = async () => {
        for (const elem of data) {
          if (elem.ebook_type === 'epub' && (elem?.status_epub == undefined || elem?.status_epub?.valid == false)) {
            setData(data.map(data => {
              if (data.provider_id === elem.provider_id) {
                data.loading = true;
              }
              return data;
            }));
      
            const result = await callValidateEpub(elem);
            if (result && result.status === 'success') {
              setData(data.map(data => {
                if (data.provider_id === elem.provider_id) {
                  data.status_epub = result.data;
                  data.loading = false;
                }
                return data;
              }));
            }
          }
        }
      };

      const callNotify = async (elem) => {
        const response = await fetch(`${url}${endpointNotify}?onix_id=${props.onixId}&provider_id=${elem.provider_id}&ink_id=${elem.ink_id}&notification_type=${elem.notification_type}&isbn=${elem.isbn}&ebook_type=${elem.ebook_type}`);
        const data = await response.json();
        return data;
      }
      
    const notify = async () => {
        for (const elem of data) {
          if (elem.notification_type === '05' || (elem.ink_id !== undefined && elem.uploaded !== undefined && elem.notified != true)) {
            setData(data.map(data => {
              if (data.provider_id === elem.provider_id) {
                data.loading = true;
              }
              return data;
            }));
      
            const result = await callNotify(elem);
            if (result && result.status === 'success') {
              setData(data.map(data => {
                if (data.provider_id === elem.provider_id) {
                  data.notified = result.data;
                  data.loading = false;
                }
                return data;
              }));
            }
          }
        }
      };

   

    return (
        <>
            
            <NavProducts handleClickTab={handleClickTab}/>
            <section className='toolbar'>
                {tabSelected !== '05' && 
                    <>
                        <button onClick={(e) => handleClickValidateEpub(e)}>Validar EPUBS</button>
                        <button onClick={(e) => handleClickSendInkit(e)}>Enviar a Inkit</button>
                        <button onClick={(e) => handleClickUploadAssets(e)}>Cargar assets</button>
                    </>
                }
                <button onClick={(e) => handleClickNotify(e)}>Notificar</button>
            </section>
            <div className='total-products'>Cantidad de productos {data?.length}</div>
            <div className='product'>
                <div className='content'>
                    <div className='resumen'>
                        Datos
                    </div>
                    <div className='status'>
                        <div onClick={(e) => handleClickSortStatusEpub(e)} className='filter-epub'>
                            <MdArrowDropDown />
                        </div>
                    </div>
                </div>
            </div>
            {error && 
                <div>Error: {error.message}</div>
            }
            {isLoaded && !error
                ?   data.map((elem, index) => {
                        return  <ProductItem key={elem.provider_id} product={elem} action={action}/>
                    })
                :   <Loading />    
            }
        </>
    )
}