import { useState,useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import FileUploaderStock from './FileUploaderStock';

function UploadStock({showUpload, setShowUpload}) {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    
    setShow(false);
    setShowUpload(false)
    console.log(show);
  };

  useEffect(() => {
    console.log(showUpload);
    if(showUpload == true)
        setShow(true);
        
  }, [showUpload]);

  return (
    <>
      <Modal 
      show={show} 
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered>
        <Modal.Header closeButton>
          <Modal.Title>Cargar Xlsx</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FileUploaderStock />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
          {/* <Button variant="primary" onClick={handleClose}>
            Cargar
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UploadStock;