
//export  const url = 'https://api.distribution.ink-it.ink/api/clube/';
//export  const urlDistribution = 'https://api.distribution.ink-it.ink/api/distribution/';
//export  const urlConversion = 'https://api.distribution.ink-it.ink/api/conversion/';
//export  const url = 'http://localhost:5000/api/clube/';
//export  const urlConversion = 'http://localhost:5000/api/conversion/';
//export  const urlDistribution = 'http://localhost:5000/api/distribution/';

export const url = process.env.REACT_APP_CLUBE_API_URL;
export const urlDistribution = process.env.REACT_APP_DISTRIBUTION_API_URL;
export const urlConversion = process.env.REACT_APP_CONVERSION_API_URL;
export const urlInk = process.env.REACT_APP_INK_API_URL;

export  const endpointDataByOnix = 'data_by_onix';
export  const endpointValidateEpub = 'validate_epub';
export  const endpointDownloadInvalidEpub = 'dowload_invalid_epubs';
export  const endpointDownloadBrokenLinks = 'download_broken_links';
export  const endpointSendInkit = 'send_metadata_to_ink';
export  const endpointUploadAssets = 'upload_assets';
export  const endpointNotify = 'send_status_ebook_inkit';
export  const endpointLogin = 'login';
export  const endpointOnixList = 'onix_list';
export  const endpointSetNotifications = 'set_notifications';
export  const endpointLastOnix = 'new_onix';
export  const endpointChannels = 'get_channels_delivery';
export  const endpointGetStock = 'get_stock';
export  const endpointDownloadCSVStock = 'download_csv_stock';
export  const endpointDownloadXlsxStock = 'download_xlsx_stock';
export  const endpointGetConversions = 'get_all';
export  const endpointUploadConversion = 'upload_conversion';
export  const endpointGetAccounts = 'api/services/accounts/get_accounts_admin.php';
export  const endpointUploadXlsx = 'upload_xlsx';
