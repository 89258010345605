import  React from "react";
import { Link } from "react-router-dom";

export function OnixItem(props) {

    console.log(props.onixId);
    return (
        <Link to={`/onix/${props.onixId}` }>
            <div className="onix-item">
                {props.onixId}
            </div>
        </Link>
    )
}