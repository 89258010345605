import React, {useState, useEffect} from 'react';

export function Sidebar(props){

    const [status, setStatus] = useState('close');

    const onClose = (e) => {
        e.preventDefault();
        setStatus('close');
        props.onClose();
    } 

/*     const onOpen = (e) => {
        e.preventDefault();
        setStatus('open');
    }  */

    useEffect(() => {
        setStatus(props.status)
    }, [status, props.status])
    

    return (
        <>
            {status === "open" &&
                <div className='sidebar'>
                    <div className='header'>
                        <div className='title'>Errores en epubs</div>
                        <button className='close' onClick={(e) => {onClose(e)}}>Close</button>
                    </div>
                    <div className='content'>
                        {props.data.msg.map((elem, index) => {
                                return <div key={index} className="status_msg">{index+1}: {elem.join(',')}</div>
                            })
                        }
                    </div>
                </div>
            }
        </>
    )
}