import React from 'react'
import { OnixList } from '../components/OnixList'
import { Heading } from '../components/Heading';
import { LastOnixList } from '../components/LastOnix';
import { NavDashboard } from '../components/NavDashboard';

export  function Index() {

  const title = 'Lista de Onix';
  return (
      <>
        <Heading title={title}/>
        <NavDashboard/>
        <LastOnixList/>
        <OnixList />
      </>
      
    )
}

export default Index