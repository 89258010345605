import  React, {useEffect, useState}  from "react";
import  iconEpub  from "../assets/epub.png";
import  iconPdf  from "../assets/pdf.png";
import { Sidebar } from "./Sidebar";

export function ProductItem(props){
    const icon = props.product.ebook_type === 'epub' ? iconEpub : iconPdf;
    const [statusEpub, setStatusEpub] = useState(null);
    const [statusEpubLabel, setStatusEpubLabel] = useState('Epub sin validar');
    const [showDetails, setShowDetails] = useState(false);
    const [showSideBar, setShowSideBar] = useState(false);
    const [statusInk, setStatusInk] = useState(false);
    const [statusInkLabel, setStatusInkLabel] = useState('Sin enviar');
    const [showLoading, setShowLoading] = useState(false);

    const [statusNotified, setStatusNotified] = useState(false);
    const [statusUploaded, setStatusUploaded] = useState(false);

    const [statusNotifiedLabel, setStatusNotifiedLabel] = useState('Sin notificar');
    const [statusUploadedLabel, setStatusUploadedLabel] = useState('Sin cargar');
    //const [action, setAction] = useState(null);

    useEffect(() => {
        setShowLoading(props.product.loading)
        if(props.product.ebook_type === 'epub'){
            setStatusEpub(props.product.status_epub);
            if(statusEpub === undefined){
                setStatusEpubLabel('Epub sin validar')
            }else if(statusEpub !== undefined && statusEpub !== null){
                statusEpub.valid ? setStatusEpubLabel('Valido') : setStatusEpubLabel('Invalido')
            }
        }
        if(props.product.ink_id !== undefined){
            setStatusInk(props.product.ink_id);
        }
            
        if(props.product.ink_id === undefined){
                setStatusInkLabel('Sin enviar');
        }else if(props.product.ink_id !== undefined){
                setStatusInkLabel('Enviado');
        }

        setStatusNotified(props.product.notified);

        if(props.product.notified === undefined){
            setStatusNotifiedLabel('Sin notificar');
        }else if(props.product.notified === true){
            setStatusNotifiedLabel('Notificado');
        }

        setStatusUploaded(props.product.uploaded);

        if(props.product.uploaded === undefined){
            setStatusUploadedLabel('Sin cargar');
        }else if(props.product.uploaded !== undefined){
            setStatusUploadedLabel('Cargado');
        }

      }, [props.product.status_epub, statusEpub, props.product.loading, 
        props.product.ebook_type, props.product.loading, props.product.inkId, 
        props.product.notified, props.product.uploaded]);

    const handleClickShowErrors = (e) => {
        e.preventDefault();
        if(statusEpub !== undefined && statusEpub.valid === false ){
            setShowSideBar('open');
        }
        
    }

    

    const handleClickShowShowDetails = (e) => {
        e.preventDefault();
        setShowDetails(!showDetails); 
    }

    const onClose = () => {
        setShowSideBar('close');
        console.log(showSideBar);
    }


    return (
        <div className="product">
            {showLoading === true && <div className="loading">Procesando...</div>}
            <div className="content">
                {props.product.notification_type !== '05' && <img src={icon} className="icon" alt="icon" width={32}/>}
                <div onClick={(e) => handleClickShowShowDetails(e) } className="resumen">
                    <div>{props.product.title}</div>
                    <div>Clube ID: {props.product.provider_id}</div>
                    {props.product.notification_type !== '05' &&
                        <>
                            <div>Ink ID: {props.product.ink_id}</div>
                            <div>ISBN: {props.product.isbn}</div>
                        </>
                    }
                    
                    
                </div>
                <div className="status">
                    {props.product.ebook_type === 'epub' && 
                        <>
                            <div onClick={(e) => handleClickShowErrors(e) } className="item">{statusEpubLabel}</div>
                            
                        </>
                    }  
                    {
                        props.product.notification_type !== '05' && 
                        <>
                            <div className="item">{statusInkLabel}</div>
                            <div className="item">{statusUploadedLabel}</div>
                        </>
                    }
                    
                    <div className="item">{statusNotifiedLabel}</div>
                </div>
            </div>
            {showDetails &&
                <div className="details">
                    <div>Autor: {props.product.author}</div>
                    
                    <div>Fecha de publicación: {props.product.publication_date}</div>
                </div>
            }   
            <Sidebar status={showSideBar} data={statusEpub} onClose={onClose}/>
        </div>
    )
}