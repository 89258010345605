import React, { useState } from 'react';
import { endpointUploadConversion, urlConversion } from '../services/Endpoints';
import { useNavigate } from 'react-router-dom';

const FileUploader = (props) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [dragging, setDragging] = useState(false);
  const navigate = useNavigate();
  const [uploading, setUploading] = useState(false);
  const [labelButton, setLabelButton] = useState('Subir archivo');

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const file = e.dataTransfer.files[0];
    setSelectedFile(file);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const handleUpload = async () => {
    // Aquí puedes agregar la lógica para subir el archivo al servidor
    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('account', props.account);
      formData.append('ink_id', props.ink_id);
      
      // Aquí debes realizar la solicitud HTTP para cargar el archivo
      // Ejemplo de solicitud POST con fetch:

      setUploading(true);
      setLabelButton('Cargando...');
      const response = await  fetch(`${urlConversion}${endpointUploadConversion}`, {
        method: 'POST',
        body: formData
      });

      const res = await response.json();
      if(res && res.status == 'success'){
        alert('Conversión cargada correctamente.');
        navigate('/conversions');

      }else{
        alert('Error al cargar la conversión.');
      }
      setUploading(false);
      setLabelButton('Subir archivo');
     
    } else {
      alert('Por favor, seleccione un archivo.');
    }
  };

  const handleDelete = () => {
    setSelectedFile(null);
  };

  return (
    <div>
      <label
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        htmlFor="fileInput"
        style={{ border: `2px dashed ${dragging ? 'blue' : 'black'}`, padding: '60px', marginBottom: '20px', display: 'block', cursor: 'pointer' }}
      >
        Arrastra y suelta el archivo aquí, o haz clic para seleccionar uno.
        <input
          type="file"
          id="fileInput"
          onChange={handleFileChange}
          style={{ display: 'none' }}
          accept=".epub"
        />
      </label>
      <div className='d-flex justify-content-center'>
        <button onClick={handleUpload} disabled={uploading}>{labelButton}</button>
      </div>
      
      {selectedFile && (
        <div>
          <strong>Archivo Seleccionado:</strong> {selectedFile.name}
          <div>
            <a href="javascript:void()" onClick={handleDelete} className='action'>Eliminar selección</a>
          </div>
          
          {/* <button onClick={handleDelete} >Eliminar Selección</button> */}
        </div>
      )}
    </div>
  );
};

export default FileUploader;
