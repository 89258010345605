import React from "react";
import { useNavigate } from "react-router-dom";

export function NavDashboard(props){

    const handleClickGo = (event) => {
        navigate('/dashboard')
    };

    const navigate = useNavigate();
    return(
        <div className="nav-db">
            <div onClick={(e) => handleClickGo(e)}>Ir al Dashboard</div>
        </div>
    )  
}