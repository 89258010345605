import React,{ useState, useEffect } from "react";
import { urlDistribution, endpointChannels } from "../services/Endpoints";
import { Loading } from "./Loading";

export function Channels(props){

    const [channels, setChannels] = useState([]);
    const [show, setShow] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      getChannels();
        return() => {
          setChannels(() => [])
        }
    }, []);

    const handleClickUpdate = (e) => {
        e.preventDefault();
        getChannels()
    }

    const handleClickShowNotifications = (e, channel) => {
      e.preventDefault();

      const updatedChannels = channels.map((element) => {
          if (channel === element.channel && element.show == false) {
              return { ...element, show: true };
          } else {
              return { ...element, show: false };
          }
      });

      setChannels(updatedChannels);
    }

    const getChannels = async (event) => {
        try {
          setLoading(true);
          const response = await fetch(`${urlDistribution}${endpointChannels}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json"
            }
          });
          const res = await response.json();
          console.log(res.data.status);
          if(res && res.status == 'success'){
            setChannels(res.data.channels);

          }else{
            
          }
          setLoading(false);

        } catch (error) {
          setLoading(false);
          console.error("Request failed:", error);
        }
    };

    return(
        <div>
          <button onClick={(e) => handleClickUpdate(e)}>Actualizar</button>
          {loading == true &&
            <Loading />
          }
          <ul className="channels-list">
          {
            channels.map((channel, index) => {
              return  <>
                      <li key={index} onClick={(e) => handleClickShowNotifications(e, channel.channel)} className="channel-item">
                        <div className="d-flex">
                          <div>
                            {channel.channel}
                          </div>
                          {channel.notification_count > 0 && 
                            <div className="notification-count">
                              {channel.notification_count}
                            </div>
                          }
                        </div>
                      </li> 
                        {channel.show == true &&
                          <table>
                            <thead>
                              <tr><th>Ink id</th><th>Notificación</th></tr>
                            </thead>
                            <tbody>
                              {channel.notifications.map((notification, index) => {
                                return  <tr key={index}>
                                          
                                          <td>{notification.account}-{notification.ink_id}</td>
                                          <td>{notification.notification_type}</td>
                                        </tr>
                              })}
                              </tbody>  
                          </table>
                        }
                      </>
            })
          }
          </ul>
        
        </div>
    )  
}

export default Channels