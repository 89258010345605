import React, { useState } from "react";

export function NavProducts(props){

    const tabsData = [
        {label: 'Nuevo', notificationType: '03', selected: true},
        {label: 'Actualizaciones', notificationType: '04', selected: false},
        {label: 'Eliminar', notificationType: '05', selected: false}
    ];

    const [tabs, setTabs] = useState(tabsData);

    const handleClick = (notificationType, event) => {
        event.preventDefault();
        changeTab(notificationType);
        props.handleClickTab(notificationType, event)
    }

    var changeTab = (notificationType) => {
        tabsData.forEach(tab => {
            if(tab.notificationType === notificationType){
                tab.selected = true;
            }else{
                tab.selected = false;
            }
        });
        setTabs(tabsData);
    }
    

    return(
        <nav className='products'>
            <ul>
                {tabs.map((tab, index) => {
                    return <li key={index} className={tab.selected === true ? 'selected' : ''} 
                                    onClick={(e) => {   handleClick(tab.notificationType, e)
                                                    }}>
                                {tab.label}
                            </li>
                })}

            </ul>
        </nav>
    )
}