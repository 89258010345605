import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export const Modules = () => {
    const navigate = useNavigate();

    const handleClickOnixList = (e) => {
        e.preventDefault();
        navigate('/onix');
    }

    const handleClickStock = (e) => {
        e.preventDefault();
        navigate('/stock');
    }

    const handleClickDelivery = (e) => {
        e.preventDefault();
        navigate('/delivery');
    }

    const handleClickConversions = (e) => {
        e.preventDefault();
        navigate('/conversions');
    }

    return (
        <div className="modules">
            <div className="module">
                <div onClick={(e) => handleClickOnixList(e)}>Clube de autores</div>
            </div>
            <div className="module">
                <div onClick={(e) => handleClickStock(e)}>Catálogo</div>
            </div>
            <div className="module">
                <div onClick={(e) => handleClickDelivery(e)}>Delivery</div>
            </div>
            <div className="module">
                <div onClick={(e) => handleClickConversions(e)}>Conversiones</div>
            </div>
        </div>
       
    );
}
