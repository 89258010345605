import React from 'react'

import { Heading } from '../components/Heading';
import { NavDashboard } from '../components/NavDashboard';
import StockList from '../components/StockList';

export  function Stock() {

    const title = 'Catálogo';
    return (
        <>
          <Heading title={title}/>
          <NavDashboard/>
          <StockList/>
        </>
        
      )
  }
  
  export default Stock