import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';

const WindowStatusProcess = ({ requestStatus, responseText }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    // Mostrar la ventana cuando el estado de la petición cambie
    if (requestStatus === 'pending') {
      setVisible(true);
    } else {
      //setVisible(false);
    }
  }, [requestStatus]);

  const handleDownload = () => {
    // Crear un Blob con el texto de la respuesta
    const blob = new Blob([responseText.join('\n')], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);

    // Crear un enlace de descarga y simular un clic en él
    const link = document.createElement('a');
    link.href = url;
    link.download = 'status.txt';
    document.body.appendChild(link);
    link.click();

    // Limpiar el URL creado para evitar fugas de memoria
    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  };

  const handleClickClose = () => {
    setVisible(false);
  }

  return (
    <div className='window-background-process' style={{display: visible ? 'block' : 'none' }}>
        <div className='d-flex align-items-center'>
            <div>Notificaciones</div>
            <div className='action' style={{marginLeft: 'auto'}} onClick={handleClickClose}><FontAwesomeIcon icon={faClose} /></div>
        </div>
      {requestStatus === 'pending' && <p>Procesando...</p>}
      {requestStatus === 'completed' && (
        <div>
          <p>Terminado.</p>
          <button onClick={handleDownload}>Descargar</button>
        </div>
      )}
    </div>
  );
};

export default WindowStatusProcess;
