import { React, useState } from "react";
import {url, endpointLastOnix} from '../services/Endpoints'
import { useNavigate } from "react-router-dom";

export function LastOnixList(){

    const [sending, setSending] = useState(false);
    const [labelButton, setLabelButton] = useState('Obtener último Onix');

    const navigate = useNavigate();


    const handleClickLastOnix = async (event) => {
        event.preventDefault();
        try {
            setSending(true);
            setLabelButton('Buscando...')
          const response = await fetch(`${url}${endpointLastOnix}`, {
            method: "GET"
          });
          const data = await response.json();
          if(data && data.status == 'success'){
            alert(data.msg);
            navigate('/onix')
          }else{
            alert(data.msg);
          }
          setSending(false);
          setLabelButton('Obtener último Onix');
          
          //console.log("Login successful:", data);
        } catch (error) {
          console.error("Request failed:", error);
        }
      };

    return (
        <button onClick={(e) => handleClickLastOnix(e)} disabled={sending}>{labelButton}</button>
    )
}