import React, { useState } from 'react';
import Select from 'react-select';

const Autocomplete = ({ options, onChange }) => {
  const [inputValue, setInputValue] = useState('');

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: 'white',
      borderColor: state.isFocused ? '#007bff' : '#ced4da', // Color del borde cuando el campo está enfocado o no
      '&:hover': {
        borderColor: state.isFocused ? '#007bff' : '#ced4da' // Color del borde cuando el campo está enfocado o no al pasar el ratón
      }
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#007bff' : 'white', // Color de fondo cuando la opción está seleccionada o no
      color: state.isSelected ? 'white' : 'black', // Color del texto cuando la opción está seleccionada o no
      '&:hover': {
        backgroundColor: '#ccc' // Color del borde cuando el campo está enfocado o no al pasar el ratón
      }
    })
  };

  const handleChange = (selectedOption) => {
    onChange(selectedOption);
  };

  const handleInputChange = (inputValue) => {
    setInputValue(inputValue);
  };

  // Filtrar las opciones basadas en el valor de entrada
  const filteredOptions = options.filter(option =>
    option.label.toLowerCase().includes(inputValue.toLowerCase())
  );

  return (
    <Select
      value={null} // Este componente maneja el valor seleccionado, establecerlo en null para que el componente sea controlado externamente
      options={filteredOptions}
      onChange={handleChange}
      onInputChange={handleInputChange}
      inputValue={inputValue}
      placeholder="Buscar..."
      styles={customStyles}
      theme={theme => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: '#007bff', // Color del borde y del texto de la opción seleccionada
          primary75: '#c8daff', // Color de fondo cuando el campo está enfocado pero no activo
          primary50: '#d6d6d6', // Color de fondo cuando el campo está desactivado
          primary25: '#e9ecef', // Color de fondo del menú desplegable
          neutral0: 'white', // Color de fondo del control
          neutral5: '#ced4da', // Color del borde cuando el campo no está enfocado
          neutral10: '#ced4da' // Color de fondo de la opción cuando está desactivada
        }
      })}
    />
  );
};

export default Autocomplete;
